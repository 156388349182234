import { render, staticRenderFns } from "./studentsByLocalTraining.vue?vue&type=template&id=5acc6484&scoped=true&"
import script from "./studentsByLocalTraining.js?vue&type=script&lang=js&"
export * from "./studentsByLocalTraining.js?vue&type=script&lang=js&"
import style0 from "./studentsByLocalTraining.scss?vue&type=style&index=0&id=5acc6484&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5acc6484",
  null
  
)

export default component.exports